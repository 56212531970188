<template>
    <div>
        <div v-if="!loaded">
            <h3>Chargement...</h3>
        </div>
        <div v-else>
            <div v-if="senders.length == 0" align="center">
                <br />
                <h2 color="primary">Il y a du nouveau chez Studioconnect !</h2>
                <br />
                <h3>Intégration Mailjet</h3>
                <br />
                <h5>Afin d'améliorer notre service de création de campagne, nous avons lié la solution Mailjet à Studioconnect.</h5>
                <br />
                <h5>Pourquoi Mailjet? C'est Français (Cocorico !) et ils offrent 6000 envois d'emails par mois, (200 par jour) gratuitement !</h5>
                <br />
                <h5>Pour en profiter, rien de plus simple ! Il vous suffit de : créer un compte Mailjet et le lier avec Studioconnect.</h5>
                <br/>
                <vs-button color="primary" type="filled" @click="toPage('/admin/option/externe')">Lets' go !</vs-button>
            </div>
            <div v-else>
                <template v-if="list_member.length > 1">
                    <h2>Envoi un Email à {{ nb_mail }} Client(s)</h2>
                </template>
                <template v-if="list_member.length==1">
                    <h2>Envoyer un Email à <b>{{ list_member[0].first_name }} {{ list_member[0].last_name }}</b></h2>
                </template>
                <hr/>
                <br/>
                <vs-row>
                    <vs-col vs-xs="12" vs-lg="6" class="px-3">
                        <!-- <h5>Nom de l'émetteur</h5>
                        <vs-input v-model="senderName" class="w-full" /> -->
                        <h5 class="mb-2">Sujet de l'Email :</h5>
                        <vs-input v-model="title" class="w-full"/>
                    </vs-col>
                    <vs-col vs-xs="12" vs-lg="6" class="px-3">
                        <h5 class="mb-2">Émetteur :</h5>
                        <div class="mt-1" v-for="s in senders" :key="s.Email">
                            <vs-radio v-model="selectedSender" vs-name="selectedSender" :vs-value="s.Email"  >{{s.Name+ ' - ' + s.Email}}</vs-radio> <br />
                        </div>
                    </vs-col>
                </vs-row>
                <!-- <vs-row>
                    <vs-col vs-xs="12" vs-lg="6" class="px-3">
                        <h5>Nom de la campagne :</h5>
                        <vs-input v-model="campaignName" class="w-full"/>
                    </vs-col>
                    <vs-col vs-xs="12" vs-lg="6" class="px-3">
                        <h5>Sujet de l'Email :</h5>
                        <vs-input v-model="title" class="w-full"/>
                    </vs-col>
                </vs-row> -->
                
                <br/>
                <br/>
                <h5>Template du message :</h5>
                <br />
                <p>La variable [PRENOM] sera remplacée par le prénom du client.</p>
                <br />
                <!--<vs-textarea v-model="template" />-->
                <ckeditor :editor="editor" v-model="template" :config="editorConfig"></ckeditor>
                <br/>
                Nombre d'Email à envoyer: <b>{{ list_member.length }}</b>
                <br/>
                <vs-divider/>
                <vs-row>
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-xs="12">
                        <vs-button class="mx-3" color="primary" type="filled" v-on:click="retour()">Retour</vs-button>
                        <vs-button class="mx-3" color="danger" type="filled" v-on:click="sendTest()">M'envoyer un email test</vs-button>
                        <vs-button class="mx-3" color="success" type="filled" v-on:click="sendCampaign()" :disabled="send_mail">Envoyer la campagne</vs-button>
                    </vs-col>
                </vs-row>
            </div>
        </div>
    </div>
</template>



<style lang="scss">
</style>

<script>

import autoRefresh from '@/assets/utils/autoRefresh'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export default {
    data(){
        return{
            list_member:[],
            title : "",
            template: "Bonjour [PRENOM],",

            loaded: false,

            nb_mail: 0,
            send_mail: false,

            senders: [],
            selectedSender: '',
            // senderName: '',
            // campaignName: '',

            editor: ClassicEditor,
            editorData: '',
            editorConfig: {
                // The configuration of the editor.
            },
        }
	},
	mounted:function()
	{
        this.list_member = [];

        this.getMailProvider();

        //autoRefresh
		autoRefresh.SetCallback(()=>
        {
            if( window.api_selected != undefined)
            {
                this.list_member = window.api_selected;
                window.api_selected = undefined;
            }
            if(this.list_member.length == 0 )
            {
                this.retour()
                return;
            }
            this.updateInfo()
        })
        autoRefresh.refresh()
    },
    watch:{
        $route (){
        },
        template(){
            this.updateInfo()
        }
    },
    methods: {
		retour()
		{
			this.$router.go(-1)
		},
        updateInfo()
        {
            this.nb_mail = 0;
            for( let i=0; i<this.list_member.length; i++)
            if( this.list_member[i].email )
                this.nb_mail++;
        },
        testElements() {

            if( this.send_mail == true)
                return false;

            // if(this.senderName.length == 0 )
            // {
            //     alert("Le nom de l'émetteur n'est pas valide");
            //     return false;
            // }
            if(this.selectedSender.length == 0 )
            {
                alert("Aucun émetteur sélectionné");
                return false;
            }
            if(this.title.length == 0 )
            {
                alert("Aucun sujet saisi");
                return false;
            }
            if(this.template.length == 0 )
            {
                alert("Aucun message saisi");
                return false;
            }
            if(this.list_member.length == 0 )
            {
                alert("Aucun client sélectionné")
                return false;
            }

            let tab_uid = [];
            for( let i=0; i<this.list_member.length; i++)
                tab_uid.push( this.list_member[i].uid );

            let today = new Date()
            let campaign = `${this.title} ${today.toISOString()}`

            let mailElements = {
                title    : this.title,
                template : encodeURI(this.template),
                tab_uid  : tab_uid.join(','),
                sender   : this.selectedSender,
                // name     : this.senderName,
                campaign : campaign,
            }

            return mailElements

        },
        getMailProvider() {
            this.senders = []
            this.$srvApi.Req('get', 'studio/mailjetcampaign')
            .then((rep)=>{
                if (rep.data) {
                    for(let p of rep.data.Data) {
                        let sender = {
                            Email: p.Email,
                            Name: p.Name,
                        }
                        if (p.Status == 'Active') this.senders.push(sender)
                    }
                }
                this.loaded = true
            }).catch((err) => {
                alert(err.message)
            });
		},
        sendTest()
		{
            let elems = this.testElements()
            if(elems) {

                if(!confirm("Envoyer un e-mail de test?"))
                    return;
                
                //lock
                this.send_mail = true;
            
                let uid = this.$srvApi.getLoginUserUid()

                elems.tab_uid = [uid]

                elems.campaign = 'Test - ' + elems.campaign
                elems.title = 'Test - ' + elems.title

                elems.tab_uid = elems.tab_uid.join(',')

                //Send envoi
                this.sendMailer(elems, true)
            }
		},
        sendCampaign() {
            let elems = this.testElements()
            if(elems) {
            
                if(!confirm("Confirmez-vous l'envoi ?"))
                    return;
                
                //lock
                this.send_mail = true;

                //Send envoi
                this.sendMailer(elems, false)
            }
		},
        sendMailer( e, test ) {
            this.$srvApi.Req('post', 'studio/mailjetcampaign', e )
            .then((rep)=>{
                if(test) {
                    alert(`Un e-mail test a été envoyé à l'adresse ${rep.data.Messages[0].To[0].Email}.`)
                    this.send_mail = false;
                }
                else alert(`Envoi effectué pour un total de ${rep.data.Messages.length} e-mails`)
            }).catch(() => {
                //console.log(err.message)
            });

        },
        toPage( url )
		{
			this.$router.push(url).catch(() => {});
		}
    }
};

</script>